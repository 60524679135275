import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'

export default function AlertDialog({ open, handleClose, severity, message }) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <Alert severity={severity} >
                    <DialogContentText id="alert-dialog-description">
                        <AlertTitle fontSize={"18px"} fontWeight="700">{severity}</AlertTitle>
                        {message}
                    </DialogContentText>
                </Alert>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button variant="outlined" color={severity} onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
